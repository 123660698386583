<template>
    <div class="fix-center">
        <div class="content content_block">
            <h1>Your <span class="strike">crypto</span> command center.</h1>
            <div class="flex_3">
                <div class="item">
                    <h3>Screx token</h3>
                    <p>Maximize the benefits of the Screx ecosystem.</p>
                </div>
                <div class="item">
                    <h3>Mulitchain</h3>
                    <p>Interoperability that optimizes transaction cost, speed, and scalability.</p>
                </div>
                <div class="item">
                    <h3>Wallet integration</h3>
                    <p>Flexibility access method through a third-party or native wallet.</p>
                </div>
                <div class="item">
                    <h3>Privacy flexibility</h3>
                    <p>A new level of control and privacy within the DeFi ecosystem.</p>
                </div>
                <div class="item">
                    <h3>Powerful analitycs</h3>
                    <p>Analyze on-chain and off-chain data for deeper market insights.</p>
                </div>
                <div class="item">
                    <h3>AI assistant</h3>
                    <p>AI-driven support for transactions, analytics, and platform functionalities.</p>
                </div>
            </div>
        </div>
        <ScrexView />
    </div>
</template>

<script>
// import { ref, onMounted, onUnmounted } from 'vue';
import ScrexView from '@/components/ScrexView.vue';

export default {
    name: 'FeaturesView',
    components: {
        ScrexView
    },
//     setup() {
//     const isMobile = ref(window.innerWidth <= 950);

//     const updateScreenSize = () => {
//       isMobile.value = window.innerWidth <= 950;
//     };

//     onMounted(() => {
//       window.addEventListener('resize', updateScreenSize);
//     });

//     onUnmounted(() => {
//       window.removeEventListener('resize', updateScreenSize);
//     });

//     return {
//       isMobile,
//     };
//   },
}
</script>

<style scoped>
    h1 {
        font-size: 70px;
        font-weight: 600;
    }
    h1 span.strike {
        position: relative;
    }
    h1 span.strike::after {
        content:'';
        display: inline-block;
        width: 100%;
        height: 3px;
        background-color: #fff;
        position: absolute;
        top: 60%;
        left: 0;
    }
    @media(max-width: 980px) {
        h1 {
            font-size: 70px;
            font-weight: 600;
        }
        
        body .fix-center .content_block h1 {
            margin-bottom: 20px;
            font-size: 48px;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
        }
        body .flex_3 {
            gap:20px;
            padding-bottom: 0px;
        }
        body .flex_3 > div {
            flex:1 1 calc(50% - 15px);
            margin-top: 30px;
        }
        body .flex_3 > div h3 {
            font-size: 21px;
        }
        body .flex_3 > div p {
            font-size: 15px;
        }
    }   
    @media(max-width: 600px) {
        body .fix-center .content {
            margin-top:100px;
        }
        body .fix-center .content_block h1 {
            font-size: 48px;
            font-weight: 600;
        }
        body .flex_3 {
            padding-bottom: 100px;
        }
        .screx-bottom {
            display:none;
        }
    }
</style>
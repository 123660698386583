<template>
  <div class="fix-center">
    <ScrexView />
    <div class="content">
      <HelloWorld v-if="!isMobile" />
      <HelloWorldMobile v-else />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue';
import HelloWorldMobile from '@/components/HelloWorldMobile.vue';
import ScrexView from '@/components/ScrexView.vue';

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    HelloWorldMobile,
    ScrexView,
  },
  setup() {
    const isMobile = ref(window.innerWidth <= 950);

    const updateScreenSize = () => {
      isMobile.value = window.innerWidth <= 950;
    };

    onMounted(() => {
      window.addEventListener('resize', updateScreenSize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateScreenSize);
    });

    return {
      isMobile,
    };
  },
};
</script>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Features from '../views/FeaturesView.vue'
import Dapp from '../views/DappView.vue'
import Safu from '../views/SafuView.vue'
import Join from '../views/JoinView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Screx' }, 
  },
  {
    path: '/features',
    name: 'features',
    component: Features,
    meta: { title: 'Screx' }, 
  },
  {
    path: '/safu',
    name: 'safu',
    component: Safu,
    meta: { title: 'Screx' }, 
  },
  {
    path: '/join',
    name: 'join',
    component: Join,
    meta: { title: 'Screx' }, 
  },
  {
    path: '/dapp',
    name: 'dapp',
    component: Dapp,
    meta: { title: 'Screx' }, 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

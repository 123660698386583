<template>
  <div class="container-body">
    <div class="left">
      <a href="https://drive.google.com/file/d/1KByLRxtgYMiFP9YXnTLJcOVcHQ0HjaDH/view" target="_blank" class="btn_text">Blackpaper</a>
      <h1>Control every step with one <span class="strike">powerful</span> platform.</h1>
    </div>
    <div class="right">
      <p>Screx empowers users with an intuitive, streamlined experience that significantly enhances the value and accessibility of decentralized finance.</p>
      <div class="buttons_group">
          <router-link to="/join" class="btn_std">
            Buy SCRX
          </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';

export default {
  name: 'HelloWorldMobile',
  setup() {
    function sayHello() {
      console.log('Hello');
    }

    const pension = ref(0);

    function sayGoodbye() {
      pension.value++;
    }

    const state = reactive({
      count: 0
    })

    return { sayHello, sayGoodbye, pension, state };
  }
}
</script>

<style scoped>
h1 span.strike {
  position:relative;
}
h1 span.strike::after {
    content:'';
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    bottom:7px;
    left: 0;
}
@media(max-width: 980px) {
  .fix-center .container-body h1 {
    margin-bottom: 40px;
    font-size: 35px;
  }
  body .fix-center .container-body {
    gap: 0;
  }
  body .fix-center .container-body p {
    font-size: 21px;
  }
  .fix-center .container-body .btn_std {
    max-width: 171px;
  }
  .fix-center .container-body .btn_text {
    text-align: left;
    margin-left: auto;
    margin-right: 0;
    display: block;
    margin-bottom: 34px;
  }
}
</style>

<template>
    <div class="screx-bottom"></div>
</template>

<script>
export default {
    name: "ScrexView"
}
</script>

<style scoped>
    .screx-bottom {
        position: fixed;
        bottom:0;
        right:0;
        width:859px;
        height: 260px;
        background-image: url('@/assets/screx.svg');
        background-size: auto;
        background-repeat: no-repeat;
        background-position: right bottom;
    }
    @media(max-width:1150px) {
        .screx-bottom {
            width: 100%;
            max-width: 420px;
            background-size: 100%;
            right: 0;
            background-position: right bottom;
        }
    }
    @media(max-width:789px) {
        .screx-bottom {
            width: 100%;
            max-width: 420px;
            background-size: 100%;
            right: unset;
            background-position: center bottom;
            z-index:-1;
        }
    }
</style>